<template>
     <div class="card_list">
          <MemberTitle memberTitle="银行卡"></MemberTitle>
          <div v-if="!firstLoad">
               <div class="card_container" v-if="bankCards.length">
                    <div class="title">银行卡</div>
                    <div class="add_line flex_row_start_center">
                         <div class="add_icon" @click="addCard">+ 添加银行卡</div>
                    </div>
                    <div class="flex_row_start_start" style="margin-top: 20px;flex-wrap: wrap;">
                         <div class="card_item" :style="{backgroundImage:`url(${card_bg})`}"
                              v-for="(item,index) in bankCards" :key="index">
                              <div class="line_first flex_row_between_center">
                                   <span class="cardName">{{item.depositBank}}</span>
                                   <div>
                                        <span style="margin-right:10px">默认</span>
                                        <el-switch @change="defaultChange(item,$event)" v-model="item.isDefault"
                                             :active-value="1" :inactive-value="0"
                                             style="--el-switch-on-color: #e2231a;">
                                        </el-switch>
                                   </div>
                              </div>

                              <div class="line_second flex_row_between_center">
                                   <span class="cardNo">{{item.bankAccount}}</span>
                                   <div @click="disable(item)">解绑</div>
                              </div>
                         </div>
                    </div>
               </div>

               <div class="result" v-else>
                    <AuthResult></AuthResult>
               </div>
          </div>
          <div>
          </div>
     </div>
</template>

<script>
     import MemberTitle from '@/components/MemberTitle';
     import { useRouter } from 'vue-router'
     import { getCurrentInstance, onMounted, reactive, ref } from "vue";
     import { ElMessage, ElPagination } from "element-plus";

     export default {
          components: {
               MemberTitle
          },

          setup() {
               const card_bg = require('@/assets/card_bg.png')
               const empty_card = require('@/assets/empty_card.png')
               const router = useRouter()
               const { proxy } = getCurrentInstance();
               const bankCards = ref([])
               const accountInfo = ref(0)
               const addCard = () => {
                    if (accountInfo.value.state == 0) {
                         proxy.$confirm('尚未实名认证，完成后即可进行绑卡操作', '提示', {
                              confirmButtonText: '立即认证',
                              cancelButtonText: '取消',
                              center: true,
                              type: 'warning'
                         }).then(() => {
                              router.push('/member/auth')
                         })
                         return
                    }
                    router.push('/member/addCard')
               }
               const firstLoad = ref(true)

               const getBankCard = () => {
                    proxy.$get('v3/member/front/bankAccount/list').then(res => {
                         firstLoad.value = false
                         if (res.state == 200) {
                              bankCards.value = res.data.list
                         }
                    })
               }



               const defaultChange = (item, e) => {
                    let { accountId } = item
                    proxy.$post('v3/member/front/bankAccount/isDefault', {
                         accountId,
                         isDefault: e
                    }).then(res => {
                         if (res.state == 200) {
                              ElMessage.success(res.msg)
                              getBankCard()
                         } else {
                              ElMessage.warning(res.msg)
                         }
                    })
                    console.log(item, e)
               }


               const disable = (item) => {
                    proxy.$confirm('是否确认解绑该银行卡？', '提示', {
                         confirmButtonText: '确认',
                         cancelButtonText: '取消',
                         type: 'warning',
                         center: true
                    }).then(res => {
                         let { accountId } = item
                         proxy.$post('v3/member/front/bankAccount/unBind', { accountId }).then(res => {
                              if (res.state == 200) {
                                   ElMessage.success(res.msg)
                                   getBankCard()
                              } else {
                                   ElMessage.warning(res.msg)
                              }
                         })
                    })
               }



               const varifyAccount = () => {
                    proxy.$get('v3/member/front/openAccount/detail').then(res => {
                         if (res.state == 200) {
                              accountInfo.value = res.data
                         }
                    })
               }

               const AuthResult = (props, context) => {
                    return (
                         <div style="width:100%;" className="res_state flex_column_center_center">
                              <img src={empty_card} style="width:259px;height:163px" />
                              <p>尚未绑定银行卡</p>
                              <div className="button_primary" onClick={addCard}>添加银行卡</div>
                         </div>
                    )
               }

               onMounted(() => {
                    getBankCard(),
                         varifyAccount()
               })


               return {
                    card_bg,
                    AuthResult,
                    firstLoad,
                    bankCards,
                    defaultChange,
                    disable,
                    addCard
               }
          }
     }
</script>


<style lang="scss">
     .card_list {
          width: 1007px;
          margin-left: 10px;
          float: left;

          .card_container {
               background-color: #fff;
               padding: 20px;

               .title {
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
                    text-align: left;
               }

               .add_line {
                    height: 40px;
                    background: #FEF4F3;
                    margin-top: 20px;
                    padding-left: 10px;

                    .add_icon {
                         width: 100px;
                         height: 28px;
                         background: #FFFFFF;
                         border-radius: 4px;
                         font-size: 13px;
                         font-family: Microsoft YaHei;
                         font-weight: 400;
                         color: #E2231A;
                         line-height: 28px;
                         text-align: center;
                         cursor: pointer;
                    }
               }


               .card_item {
                    width: 310px;
                    height: 150px;
                    margin-right: 18px;
                    padding: 0 15px;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: contain;

                    &:nth-child(3n) {
                         margin-right: 0px;
                         margin-bottom: 20px
                    }

                    .line_first {
                         margin-top: 28px;
                         font-size: 15px;
                         font-family: Microsoft YaHei;
                         font-weight: bold;
                         color: #FFFFFF;
                    }


                    .line_second {
                         margin-top: 52px;

                         span {
                              font-size: 14px;
                              font-family: Microsoft YaHei;
                              font-weight: bold;
                              color: #FFFFFF;
                         }

                         div {
                              width: 52px;
                              height: 22px;
                              border: 1px solid #FFFFFF;
                              border-radius: 11px;
                              line-height: 22px;
                              text-align: center;
                              font-size: 13px;
                              font-family: PingFang SC;
                              font-weight: 500;
                              color: #FFFFFF;
                         }
                    }
               }

          }

          .result {
               background: #fff;
               height: 970px;

               .res_state {
                    padding-top: 100px;

                    p {
                         margin-top: 31px;
                         font-size: 16px;
                         font-family: Microsoft YaHei;
                         font-weight: 400;
                         color: #333333;
                    }

                    span {
                         margin-top: 16px;
                         font-size: 14px;
                         font-family: Microsoft YaHei;
                         font-weight: 400;
                         color: #999999;
                         margin-bottom: 31px;
                    }
               }

               .button_primary {
                    width: 150px;
                    height: 40px;
                    background: #E73439;
                    border-radius: 4px;
                    text-align: center;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #FFFFFF;
                    line-height: 40px;
                    cursor: pointer;
                    margin-top: 30px;
               }
          }
     }
</style>